
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPageBlogPostLayout} from 'layouts/CmsPageBlogPostLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPageBlogPostLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Finalmente ho trovato il coraggio di acquistare la mia prima mountain bike.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`ROCKRIDER XC 050`}</em></strong>{` taglia L con ruote da 29″ del 2020.`}</p>
    <p>{`Perché tanta esitazione? Perché `}<strong parentName="p">{`ho letto troppo online`}</strong>{` cercando risposte. Sono ignorante sul mondo delle mountain bike e sulle nuove tecnologie.`}</p>
    <p>{`Ne ho confrontate sia online, sia in negozio. Ce ne sono di `}<strong parentName="p">{`vari di tipi per discipline diverse`}</strong>{`: `}<em parentName="p">{`XC (Cross Country)`}</em>{`, `}<em parentName="p">{`Trail`}</em>{`, `}<em parentName="p">{`All Mountain`}</em>{`, `}<em parentName="p">{`Enduro`}</em>{`, `}<em parentName="p">{`Downhill`}</em>{`… Quindi, quale sarebbe la mia?`}</p>
    <p>{`Mi sono ritrovato disorientato per aver seguito decine di forum dove ognuno esprime la propria opinione, ovviamente diversa dagli altri.`}</p>
    <p>{`Ci sono troppe informazioni contrastanti in giro, e troppe variabili in gioco su questioni che un principiante non ha modo di conoscere. Giusto per riportarne alcune:`}</p>
    <ul>
      <li parentName="ul">{`front oppure full?`}</li>
      <li parentName="ul">{`mono, doppia o tripla?`}</li>
      <li parentName="ul">{`ruote da 26″, da 27.5″ o da 29″?`}</li>
      <li parentName="ul">{`ruote Boost?`}</li>
      <li parentName="ul">{`perno passante o sistema Quick-Release?`}</li>
      <li parentName="ul">{`quale forcella?`}</li>
      <li parentName="ul">{`di marca per poterla rivendere meglio?`}</li>
      <li parentName="ul">{`cavi interni al telaio?`}</li>
      <li parentName="ul">{`sterzo conico?`}</li>
      <li parentName="ul">{`reggisella telescopico?`}</li>
      <li parentName="ul">{`…`}</li>
    </ul>
    <p>{`Una mattina ho spento la testa e l’ho ordinata. Questa `}<em parentName="p">{`ROCKRIDER`}</em>{` mi sembrava la migliore come `}<strong parentName="p">{`rapporto qualità/prezzo`}</strong>{`, ma mi sembra di aver fatto un salto nel vuoto.`}</p>
    <p>{`La spesa mi lascia tuttora perplesso. Mi sarebbe bastata una bici meno `}<em parentName="p">{`pro`}</em>{`?`}</p>
    <p>{`Lo scoprirò, ma `}<strong parentName="p">{`me ne sono già innamorato`}</strong>{`. È bellissima, grande e tecnologica.`}</p>
    <p>{`Non mi resta che pedalarci sopra per conoscerla fino in fondo.`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    